<template>
  <div>
    <Loading v-show="loading"></Loading>
    <div id="chart">
      <div
        ref="lineChart1"
        :style="{ width: '100%', height: '400px'}"
      ></div>
      <div
        class="d-flex align-left flex-wrap"
      >
        <v-spacer></v-spacer>
        <v-switch
          style="width:250px"
          v-model="countDisplayswitch1"
          flat
          :label="countDisplayswitch1 ? `Count Display`:`Count Undisplayed`"
          hide-details
          @change="countDisplay1"
        ></v-switch>
      </div>
      <div
        ref="lineChart2"
        :style="{ width: '100%', height: '400px'}"
      ></div>
            <div
        class="d-flex align-left flex-wrap"
      >
        <v-spacer></v-spacer>
        <v-switch
          style="width:250px"
          v-model="countDisplayswitch2"
          flat
          :label="countDisplayswitch2 ? `Count Display`:`Count Undisplayed`"
          hide-details
          @change="countDisplay2"
        ></v-switch>
      </div>
      <div
        ref="pieChart"
        :style="{ width: '100%', height: '500px'}"
      ></div>
    </div>
    <br>
    <floorplan
      v-if="userData.image_src !== undefined"
      id="exportMapDiv"
      :img-src="require(`@/assets${userData.image_src}`)"
      :image-width="userData.image_witdh"
      :image-height="userData.image_height"
    >
    </floorplan>
    <br>
    <!-- <div>
      <v-btn
      color="primary"
      dark
      @click="exportMaptoImage"
      style="text-transform: none"
    >
      Export Image
      <v-icon
        dark
        right
      >
        {{ icons.mdiFileExportOutline }}
      </v-icon>
    </v-btn>
    </div> -->
    <br>
    <div
      v-if="!isEmpty(userData.searchType)"
      id="mainTable"
    >
      <v-col>
        <v-simple-table
          dense
          :style="userData.searchType === 1 ? 'width:1176px': 'width:1230px'"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-if="userData.searchType === 1"
                  class="text-center"
                  rowspan="3"
                >
                  Time
                </th>
                <th
                  v-if="userData.searchType === 2"
                  class="text-center"
                  rowspan="3"
                >
                  Date
                </th>
                <th
                  class="text-center"
                  style="background: #ffbbff !important"
                  colspan="6"
                >
                  1F
                </th>
                <th
                  class="text-center"
                  style="background: #F9C100 !important"
                  colspan="6"
                >
                  2F
                </th>
                <th
                  class="text-center"
                  style="background: #66ff66 !important"
                  colspan="6"
                >
                  3F
                </th>
                <th
                  class="text-center"
                  style="background: #b384ff !important"
                  colspan="2"
                >
                  BF
                </th>
              </tr>
              <tr>
                <th
                  class="text-center"
                  style="background: #ffbbff !important"
                  colspan="2"
                >
                  WC 01
                </th>
                <th
                  class="text-center"
                  style="background: #ffbbff !important"
                >
                  WC 02
                </th>
                <th
                  class="text-center"
                  style="background: #ffbbff !important"
                >
                  WC 03
                </th>
                <th
                  class="text-center"
                  style="background: #ffbbff !important"
                  colspan="2"
                >
                  WC 04
                </th>
                <th
                  class="text-center"
                  style="background: #F9C100 !important"
                  colspan="2"
                >
                  WC 05
                </th>
                <th
                  class="text-center"
                  style="background: #F9C100 !important"
                >
                  WC 06
                </th>
                <th
                  class="text-center"
                  style="background: #F9C100 !important"
                >
                  WC 07
                </th>
                <th
                  class="text-center"
                  style="background: #F9C100 !important"
                  colspan="2"
                >
                  WC 08
                </th>
                <th
                  class="text-center"
                  style="background: #66FF66 !important"
                  colspan="2"
                >
                  WC 09
                </th>
                <th
                  class="text-center"
                  style="background: #66FF66 !important"
                >
                  WC 10
                </th>
                <th
                  class="text-center"
                  style="background: #66FF66 !important"
                >
                  WC 11
                </th>
                <th
                  class="text-center"
                  style="background: #66FF66 !important"
                  colspan="2"
                >
                  WC 12
                </th>
                <th
                  class="text-center"
                  style="background: #b384ff !important"
                  colspan="2"
                >
                  WC 13
                </th>
              </tr>
              <tr>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table1Data"
                :key="item.time"
                :class="getClass(item.time, index)"
              >
                <td
                  class="text-center"
                  :style="userData.searchType === 2 ? 'width:110px':''"
                >
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td class="text-right">
                  {{ item.count14 }}
                </td>
                <td class="text-right">
                  {{ item.count15 }}
                </td>
                <td class="text-right">
                  {{ item.count16 }}
                </td>
                <td class="text-right">
                  {{ item.count17 }}
                </td>
                <td class="text-right">
                  {{ item.count18 }}
                </td>
                <td class="text-right">
                  {{ item.count19 }}
                </td>
                <td class="text-right">
                  {{ item.count20 }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.table1FooterData"
                :key="item.time"
                :class="item.time === 'Total' ? 'yellow': ''"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td class="text-right">
                  {{ item.count14 }}
                </td>
                <td class="text-right">
                  {{ item.count15 }}
                </td>
                <td class="text-right">
                  {{ item.count16 }}
                </td>
                <td class="text-right">
                  {{ item.count17 }}
                </td>
                <td class="text-right">
                  {{ item.count18 }}
                </td>
                <td class="text-right">
                  {{ item.count19 }}
                </td>
                <td class="text-right">
                  {{ item.count20 }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
      <v-col>
        <v-simple-table
          dense
          :style="userData.searchType === 1 ? 'width:896px': 'width:950px'"
        >
          >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-if="userData.searchType === 1"
                  class="text-center"
                  rowspan="2"
                >
                  Time
                </th>
                <th
                  v-if="userData.searchType === 2"
                  class="text-center"
                  rowspan="2"
                >
                  Date
                </th>
                <th
                  class="text-center"
                  style="background: #ffbbff !important"
                  colspan="3"
                >
                  1F
                </th>
                <th
                  class="text-center"
                  style="background: #F9C100 !important"
                  colspan="3"
                >
                  2F
                </th>
                <th
                  class="text-center"
                  style="background: #66FF66 !important"
                  colspan="3"
                >
                  3F
                </th>
                <th
                  class="text-center"
                  style="background: #b384ff !important"
                  colspan="3"
                >
                  BF
                </th>
                <th
                  class="text-center"
                  style="background: #FFFF00 !important "
                  colspan="3"
                >
                  Total
                </th>
              </tr>
              <tr>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #FFFF00 !important"
                >
                  TTL
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #FFFF00 !important"
                >
                  TTL
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #FFFF00 !important"
                >
                  TTL
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #FFFF00 !important"
                >
                  TTL
                </th>
                <th
                  class="text-center"
                  style="background: #66FFFF !important"
                >
                  M
                </th>
                <th
                  class="text-center"
                  style="background: #FFCCFF !important"
                >
                  F
                </th>
                <th
                  class="text-center"
                  style="background: #FFFF00 !important"
                >
                  TTL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in userData.table2Data"
                :key="item.time"
                :class="getClass(item.time, index)"
              >
                <td
                  class="text-center"
                  :style="userData.searchType === 2 ? 'width:110px':''"
                >
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td class="text-right">
                  {{ item.count14 }}
                </td>
                <td class="text-right">
                  {{ item.count15 }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr
                v-for="item in userData.table2FooterData"
                :key="item.time"
                :class="item.time === 'Total' ? 'yellow': ''"
              >
                <td class="text-center">
                  {{ item.time }}
                </td>
                <td class="text-right">
                  {{ item.count1 }}
                </td>
                <td class="text-right">
                  {{ item.count2 }}
                </td>
                <td class="text-right">
                  {{ item.count3 }}
                </td>
                <td class="text-right">
                  {{ item.count4 }}
                </td>
                <td class="text-right">
                  {{ item.count5 }}
                </td>
                <td class="text-right">
                  {{ item.count6 }}
                </td>
                <td class="text-right">
                  {{ item.count7 }}
                </td>
                <td class="text-right">
                  {{ item.count8 }}
                </td>
                <td class="text-right">
                  {{ item.count9 }}
                </td>
                <td class="text-right">
                  {{ item.count10 }}
                </td>
                <td class="text-right">
                  {{ item.count11 }}
                </td>
                <td class="text-right">
                  {{ item.count12 }}
                </td>
                <td class="text-right">
                  {{ item.count13 }}
                </td>
                <td class="text-right">
                  {{ item.count14 }}
                </td>
                <td class="text-right">
                  {{ item.count15 }}
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <br>
    </div>
    <br>
  </div>
</template>

<script>
import { mdiDownloadOutline, mdiFileExportOutline } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import store from '@/store'
import toiletStoreModule from './toiletStoreModule'
import { isEmpty, getSessionData, date2yyyymmddhhmmss } from '@core/utils'
import floorplan from '@/views/components/blueprint/FloorPlan.vue'
import eventBus from '@core/utils/eventBus'
import Loading from '@/views/components/load/Loading.vue'

const USER_APP_STORE_MODULE_NAME = 'app-toilet'

const userData = ref([])
const currentDate = new Date()
const echarts = require('echarts')

export default {
  components: {
    floorplan,
    Loading,
  },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        this.drawLine(val)
        this.drawPie(val)
      }, 500)
    },
  },
  created() {
    const startDate = sessionStorage.getItem('startDate')
    const endDate = sessionStorage.getItem('endDate')
    this.searchOperation(new Date(startDate), new Date(endDate))
  },
  mounted() {
    eventBus.$on('peopleCountSearch', () => {
      const startDate = sessionStorage.getItem('startDate')
      const endDate = sessionStorage.getItem('endDate')
      this.searchOperation(new Date(startDate), new Date(endDate))
    })

    // 日付選択コンポーネントを表示させる
    this.$dateRangePickerDisplayFlag = true
    this.$datePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
  },
  beforeDestroy() {
    // eventBus リスナーの削除
    eventBus.$off('peopleCountSearch')
  },
  methods: {
    // 検索処理を行う
    searchOperation(startDate, endDate) {
      this.loading = true
      const startDateFormatted = `${startDate.getFullYear()}
      -${(startDate.getMonth() + 1).toString().padStart(2, '0')}
      -${startDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${endDate.getFullYear()}
      -${(endDate.getMonth() + 1).toString().padStart(2, '0')}
      -${endDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const siteId = String(getSessionData('data').siteId)
      const searchStartDate = startDateFormatted
      const searchEndDate = endDateFormatted
      store
        .dispatch('app-toilet/fetchData', {
          params: {
            siteId,
            searchStartDate,
            searchEndDate,
          },
        })
        .then(response => {
          userData.value = response.data.result.data
          userData.value.labelShow1 = this.countDisplayswitch1
          userData.value.labelShow2 = this.countDisplayswitch2
          this.loading = false
        })
        .catch(error => {
          // ログイン画面に遷移する
          console.log(error)
          this.loading = false

          this.$router.push({ name: 'error-login' })
        })
    },

    getClass(time, index) {
      let css = ''

      if (this.userData.searchType === 1) {
        if (index % 2 === 0) {
          css = ''
        } else {
          css = 'Gray'
        }

        return css
      }

      if (time.indexOf('Sat') >= 0) {
        css = 'Sat'
      } else if (time.indexOf('Sun') >= 0) {
        css = 'Sun'
      }

      return css
    },
    countDisplay1() {
      this.userData.labelShow1 = this.countDisplayswitch1
      this.drawLine(this.userData)
    },
    countDisplay2() {
      this.userData.labelShow2 = this.countDisplayswitch2
      this.drawLine(this.userData)
    },

    // line chart作成する
    drawLine(val) {
      const newSeriesData1 = []

      for (let i = 0; i < Object.values(val.seriesData1).length; i += 1) {
        const element = Object.values(val.seriesData1)[i]

        let colorCd
        if (element.name === 'M') {
          colorCd = ['#66ffff']
        } else if (element.name === 'F') {
          colorCd = ['#ffccff']
        } else {
          colorCd = ['#97d357']
        }

        const newData = {
          name: element.name,
          type: element.name === 'Total' ? 'line' : 'bar',
          label: {
            normal: {
              show: val.labelShow1,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          data: element.data,
          color: colorCd,
        }
        newSeriesData1.push(newData)
      }

      const lineChartOption1 = {
        title: {
          left: '15%',
          text: val.text,
        },
        toolbox: {
          bottom: 0,
          left: 20,
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        legend: {
          orient: 'vertical',
          top: '10%',
          left: '80%',
        },
        grid: {
          left: '8%',
          right: '28%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: val.legendData1,
        },
        yAxis: {},
        series: newSeriesData1,
      }
      let lineChart1 = echarts.getInstanceByDom(this.$refs.lineChart1)
      if (lineChart1 == null) {
        lineChart1 = echarts.init(this.$refs.lineChart1)
      }

      lineChart1.setOption(lineChartOption1, true)

      const newSeriesData2 = []

      for (let i = 0; i < Object.values(val.seriesData2).length; i += 1) {
        const element = Object.values(val.seriesData2)[i]

        let colorCd
        if (element.name === 'M') {
          colorCd = ['#66ffff']
        } else if (element.name === 'F') {
          colorCd = ['#ffccff']
        } else {
          colorCd = ['#66ff99']
        }
        const newData = {
          name: element.name,
          type: 'bar',
          label: {
            normal: {
              show: val.labelShow2,
              formatter: params => {
                const v = parseFloat(params.value)

                return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              },
            },
          },
          data: element.data,
          color: colorCd,
        }
        newSeriesData2.push(newData)
      }

      const lineChartOption2 = {
        title: {
          left: '15%',
          text: val.text,
        },
        toolbox: {
          bottom: 0,
          left: 20,
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        legend: {
          orient: 'vertical',
          top: '10%',
          left: '80%',
        },
        grid: {
          left: '8%',
          right: '28%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: val.legendData2,
        },
        yAxis: {},
        series: newSeriesData2,
      }
      let lineChart2 = echarts.getInstanceByDom(this.$refs.lineChart2)
      if (lineChart2 == null) {
        lineChart2 = echarts.init(this.$refs.lineChart2)
      }

      lineChart2.setOption(lineChartOption2, true)
    },

    // pie chart作成する
    drawPie(val) {
      const percentageMap = new Map()
      if (!isEmpty(val.pieData)) {
        val.pieData.forEach(element => {
          percentageMap.set(element.name, ((element.value / val.totalData) * 100).toFixed(1))
        })
      }

      const pieChartOption = {
        toolbox: {
          bottom: 100,
          left: '5%',
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: {
          trigger: 'item',

          // formatter: '{b}: {c} ({d}%)',
          formatter: params => {
            const v = parseFloat(params.value)
            const value = String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')

            return `${params.name}: ${value} (${params.percent.toFixed(1)}%)`
          },
        },
        legend: {
          orient: 'vertical',
          right: 300,
          top: 50,
          bottom: 100,
          formatter: name => {
            const rate = percentageMap.get(name)

            return `${name} ${rate}%`
          },
        },
        color: ['#FFE3BF', '#D3ECA7', '#FFE162', '#C8F2EF', '#FFC0D3', '#8BDB81', '#BFFFF0', '#9AD0EC', '#F0ECE3', '#B5DEFF', '#D77FA1', '#F85F73'],
        series: [
          {
            // name: 'People Count',
            type: 'pie',
            radius: ['30%', '80%'],
            avoidLabelOverlap: false,
            center: ['40%', '50%'],
            label: {
              normal: {
                show: true,
                position: 'inner',
                textStyle: {
                  fontWeight: 300,
                  fontSize: 12,
                  color: 'black',
                },
                formatter: a => {
                  console.log(a)

                  return `${a.name}\n${a.percent.toFixed(1)}%`
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: val.pieData,
          },
        ],
      }
      let pieChart = echarts.getInstanceByDom(this.$refs.pieChart)
      if (pieChart == null) {
        pieChart = echarts.init(this.$refs.pieChart)
      }

      pieChart.setOption(pieChartOption, true)
      if (!isEmpty(val.pieData)) {
        this.$refs.pieChart.style.display = 'block'
      } else {
        this.$refs.pieChart.style.display = 'none'
      }
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, toiletStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const startDate = sessionStorage.getItem('startDate')
    if (isEmpty(startDate)) {
      const startDateFormatted = `${currentDate.getFullYear()}
      -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
      -${currentDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${currentDate.getFullYear()}
      -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
      -${currentDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      sessionStorage.setItem('startDate', startDateFormatted)
      sessionStorage.setItem('endDate', endDateFormatted)
    }

    const countDisplayswitch1 = ref(true)
    const countDisplayswitch2 = ref(true)

    return {
      countDisplayswitch1,
      countDisplayswitch2,
      userData,
      icons: {
        mdiDownloadOutline,
        mdiFileExportOutline,
      },
      loading: true,
      isEmpty,
    }
  },
}
</script>

<style>
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}
</style>

<style scoped>
.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}

.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}

.v-data-table tbody .Gray {
  background-color: rgb(242, 242, 242);
}

.theme--light.v-data-table td {
  color: #000 !important;
  border: 0.5px solid #000;
  padding: 0px 1px !important;
  font-size: 12px !important;
  white-space: nowrap;
  width: 56px;
}
.theme--light.v-data-table th {
  border: 0.5px solid #000;
  white-space: nowrap;
  width: 56px;
  padding: 0px 5px !important;
}
</style>
